.h-25 {
  height: 25% !important;
}
.technologyText h6 {
  width: 250px;
  overflow: hidden;
}

.sectionBoxOne.greenBoxIcon .sorting .green-btn {
  border: 1px solid #2aa406 !important;
  background: linear-gradient(60deg, #2aa406, #34b90d);
}

.sectionBoxOne.greenBoxIcon .sorting .green-btn:hover {
  background: linear-gradient(60deg, #34b90d, #2aa406);
}

.infomsg {
  background: #fbffd6;
  padding: 10px;
  font-size: 12px;
  border: 1px solid #d4de72;
}

#toast {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}
